// Generated by Framer (6d82f59)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ffRo2JhjL", "SWocg6H4N"];

const serializationHash = "framer-i2In4"

const variantClassNames = {ffRo2JhjL: "framer-v-jeex08", SWocg6H4N: "framer-v-ehenil"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Burger: "ffRo2JhjL", X: "SWocg6H4N"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ffRo2JhjL"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ffRo2JhjL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap101zey2 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("SWocg6H4N")
})

const onTap8s96h6 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("ffRo2JhjL")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-jeex08", className, classNames)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"ffRo2JhjL"} onTap={onTap101zey2} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({SWocg6H4N: {"data-framer-name": "X", onTap: onTap8s96h6}}, baseVariant, gestureVariant)}><motion.div className={"framer-1cq8mcw"} layoutDependency={layoutDependency} layoutId={"kIpIMpf6I"}><motion.div className={"framer-1xkhddn"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"tan2ZQLjs"} style={{backgroundColor: "rgb(0, 0, 0)", rotate: 0}} variants={{SWocg6H4N: {rotate: -45}}}/><motion.div className={"framer-lvhg0p"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"N3JqnxuC2"} style={{backgroundColor: "rgb(0, 0, 0)", opacity: 1}} variants={{SWocg6H4N: {opacity: 0}}}/><motion.div className={"framer-1u7zf0t"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"xwp7DH6Gy"} style={{backgroundColor: "rgb(0, 0, 0)", rotate: 0}} variants={{SWocg6H4N: {rotate: 45}}}/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-i2In4.framer-fbdfog, .framer-i2In4 .framer-fbdfog { display: block; }", ".framer-i2In4.framer-jeex08 { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-i2In4 .framer-1cq8mcw { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-i2In4 .framer-1xkhddn { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-i2In4 .framer-lvhg0p { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-i2In4 .framer-1u7zf0t { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-i2In4.framer-v-ehenil .framer-1xkhddn { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-i2In4.framer-v-ehenil .framer-lvhg0p { left: unset; width: 2px; }", ".framer-i2In4.framer-v-ehenil .framer-1u7zf0t { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SWocg6H4N":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRnOJ1bJVo: React.ComponentType<Props> = withCSS(Component, css, "framer-i2In4") as typeof Component;
export default FramerRnOJ1bJVo;

FramerRnOJ1bJVo.displayName = "nav / Menu Icon";

FramerRnOJ1bJVo.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerRnOJ1bJVo, {variant: {options: ["ffRo2JhjL", "SWocg6H4N"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerRnOJ1bJVo, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})